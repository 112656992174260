<template>
  <el-main class="rh-form">
    <form-contratedit :routetolist="{ name: 'contrats' }"></form-contratedit>
  </el-main>
</template>
<script>
import FormContratedit from "@/components/rh/formulaires/FormContratedit";

export default {
  components: { "form-contratedit": FormContratedit },
};
</script>
