<template>
  <div>
    <h1>Contrat</h1>
    <el-row style="width: 1140px" v-loading="contratLoadStatus == 1">
      <el-col>
        <form-contrat :ismeddetail="ismeddetail" ref="formCont"> </form-contrat>
      </el-col>
    </el-row>
    <el-divider />
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormContrat from "@/components/rh/ui/form/FormContrat";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-contrat": FormContrat },

  data() {
    return {
      //loadCount: 0,
    };
  },
  props: {
    ismeddetail: Boolean,
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("contl/loadContrat", this.idCont);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idCont() {
      // récupère le id du contrat dans l'url
      return String(this.$route.params.idcont);
    },
    contratLoadStatus() {
      return this.$store.state.contl.contratLoadStatus;
    },
    contratUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.contl.contratUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.contratLoadStatus == 2) {
        // charge les données du contrat
        const contrat = this.$store.state.contl.contrat;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.contloaded = JSON.stringify(contrat);
      } else if (this.contratLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formCont.$refs.formContrat.validate((valid) => {
        if (valid) {
          let contdata = this.$store.state.contl.contrat;
          // enlever les espaces avant et après dans les string
          trimStringInObj(contdata);

          // vérifie si un contrat identique existe déjà
          var id = this.idCont;
          var ContsInBD = this.$store.getters["contl/ContsInBD"];
          var contdataStr = JSON.stringify(contdata);
          if (localStorage.contloaded == contdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
          } else if (
            ContsInBD.filter(
              (m) =>
                m[0] != id &&
                m[1] == contdata.id_med &&
                m[2] == contdata.debut_date
            ).length > 0
          ) {
            const med = this.$store.getters["medl/medecinSelect"].filter(
              (m) => m.value == contdata.id_med
            )[0].label;
            this.$alert(
              "<b>" +
                med +
                " possède déjà un contrat débutant le " +
                contdata.debut_date,
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur maj l'enregistrement
            this.$store.dispatch("contl/updateContrat", contdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const contdataStr = JSON.stringify(this.$store.state.contl.contrat);
      if (localStorage.contloaded != contdataStr) {
        let dataObj = JSON.parse(localStorage.contloaded);
        this.$store.commit("contl/setContrat", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      const contdataStr = JSON.stringify(this.$store.state.contl.contrat);
      if (localStorage.contloaded == contdataStr) {
        // si pas de modifications
        // retourne à la liste des contrats
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des contrats
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    contratLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    contratUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.contratUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.contloaded = JSON.stringify(
          this.$store.state.contl.contrat
        );
      } else if (this.contratUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
