<template>
  <div class="app-form">
    <el-form
      ref="formContrat"
      :model="form"
      label-width="100px"
      label-position="right"
      :rules="rules"
      :validate-on-rule-change="false"
    >
      <!-- CHAMPS -->
      <el-row style="width: 680px">
        <el-col :span="12">
          <el-form-item label="Médecin" prop="id_med" v-show="!ismeddetail">
            <el-select
              v-model="form.id_med"
              clearable
              filterable
              placeholder="Choisissez un médecin"
            >
              <el-option
                v-for="item in medecinOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Service" prop="id_service">
            <el-select
              v-model="form.id_service"
              clearable
              filterable
              placeholder="Choisissez un service"
            >
              <el-option
                v-for="item in serviceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Poste" prop="id_poste">
            <el-select
              v-model="form.id_poste"
              clearable
              filterable
              placeholder="Choisissez un poste"
              @change="changePoste"
            >
              <el-option
                v-for="item in posteOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Taux" prop="id_taux">
            <el-select
              v-model="form.id_taux"
              clearable
              filterable
              placeholder="Choisissez un taux"
            >
              <el-option
                v-for="item in tauxOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="id_type">
            <el-radio-group
              v-model="form.id_type"
              size="small"
              @change="changeType"
            >
              <el-radio :label="1">CDD</el-radio>
              <el-radio :label="2">CDI</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Permis" prop="id_permis" v-show="false">
            <el-select
              v-model="form.id_permis"
              clearable
              filterable
              placeholder="Choisissez un permis"
            >
              <el-option
                v-for="item in permisOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="AROMU" prop="coromu">
            <el-switch
              v-model="form.coromu"
              :inactive-value="inactif"
              :active-value="actif"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Activités hors Service" prop="activite_xurg">
            <el-switch
              v-model="form.activite_xurg"
              :inactive-value="inactif"
              :active-value="actif"
              @change="clearActXurgPrec"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="Précisez : "
            prop="activite_xurg_prec"
            v-show="form.activite_xurg"
          >
            <el-input v-model="form.activite_xurg_prec"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Début" prop="debut_date" v-show="showDates">
            <el-date-picker
              v-model="form.debut_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="finPrevTxt"
            prop="fin_prevue_date"
            v-show="showDates && showfinPrev"
          >
            <el-date-picker
              v-model="form.fin_prevue_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
              @change="changeFinPrevue"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            :label="finQuestTxt"
            prop="fin_quest"
            v-show="showDates"
          >
            <el-switch
              v-model="finEffectShow"
              :inactive-value="inactif"
              :active-value="actif"
              @change="changeFinEffectShow"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            label="Fin effective"
            prop="fin_effect_date"
            v-show="showDates && showFinEffDate"
          >
            <el-date-picker
              v-model="form.fin_effect_date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="Format: aaaa-mm-jj"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="Motif de fin"
            prop="id_fin_motif"
            v-show="showDates && finEffectShow"
          >
            <el-select
              v-model="form.id_fin_motif"
              clearable
              filterable
              placeholder="Choisissez un motif"
              @change="clearFinMotifPrec"
            >
              <el-option
                v-for="item in finMotifOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Précisions : "
            prop="fin_motif_prec"
            v-show="showDates && finEffectShow && form.id_fin_motif == 4"
          >
            <el-input v-model="form.fin_motif_prec"></el-input>
          </el-form-item>
          <div class="form-item-cadre" v-show="showDates">
            <el-form-item label="Départ envisagé" prop="depart_prev_mois">
              <el-date-picker
                v-model="form.depart_prev_mois"
                type="month"
                value-format="yyyy-MM"
                placeholder="Choisissez un mois"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="Projets" prop="depart_prev_proj">
              <el-input v-model="form.depart_prev_proj"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var valideDebut = (rule, value, callback) => {
      if (value) {
        callback();
        this.$refs.formContrat.validateField("fin_prevue_date");
        this.$refs.formContrat.validateField("fin_effect_date");
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    var valideFinPrevue = (rule, value, callback) => {
      if (value) {
        if (this.form.debut_date && this.form.debut_date >= value) {
          callback(new Error("la fin doit être postérieure au début !"));
        } else {
          callback();
          this.$refs.formContrat.validateField("fin_effect_date");
        }
      } else {
        if (this.form.id_type == 2 && ![1, 2, 3].includes(this.form.id_poste)) {
          callback();
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      }
    };
    var valideFinEffect = (rule, value, callback) => {
      if (this.showFinEffDate == 1) {
        if (value) {
          if (this.form.debut_date && this.form.debut_date >= value) {
            callback(
              new Error("la fin effective doit être postérieure au début !")
            );
          } else if (
            this.form.fin_prevue_date &&
            this.form.fin_prevue_date == value
          ) {
            callback(
              new Error(
                "La fin effective doit être différente de la fin prévue !"
              )
            );
          } else {
            callback();
          }
        } else {
          callback(new Error("Ce champ est obligatoire"));
        }
      } else {
        callback();
      }
    };

    return {
      actif: true,
      inactif: false,
      finEffectShow: false,
      rules: {
        id_med: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_service: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_poste: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_taux: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        id_type: [
          {
            required: true,
            message: "Ce champ est obligatoire",
            trigger: "change",
          },
        ],
        //id_permis: [{required: true, message: 'Ce champ est obligatoire', trigger: 'change'},],
        debut_date: [
          { required: true, validator: valideDebut, trigger: "change" },
        ],
        fin_prevue_date: [
          { required: true, validator: valideFinPrevue, trigger: "change" },
        ],
        fin_effect_date: [
          { required: true, validator: valideFinEffect, trigger: "change" },
        ],
      },
    };
  },
  props: {
    ismeddetail: Boolean,
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.contl.contrat;
      },
      set(form) {
        this.$store.commit("contl/setContrat", form);
      },
    },
    contratLoadStatus() {
      return this.$store.state.contl.contratLoadStatus;
    },
    medecinOptions() {
      return this.$store.getters["medl/medecinSelect"];
    },
    serviceOptions() {
      return this.$store.getters["servl/serviceSelect"];
    },
    posteOptions() {
      return this.$store.getters["postel/posteSelect"];
    },
    tauxOptions() {
      return this.$store.getters["tauxl/tauxSelect"];
    },
    permisOptions() {
      return this.$store.getters["permisl/permisSelect"];
    },
    finMotifOptions() {
      return this.$store.getters["fmotifl/finMotifSelect"];
    },
    showDates() {
      return this.form.id_poste && this.form.id_type ? true : false;
    },
    showfinPrev() {
      // CDD ou poste de MA ou CDC, id_poste : 1 = CDC, 2 = CDC-A, 3 = MA
      return this.form.id_type == 1 || [1, 2, 3].includes(this.form.id_poste)
        ? true
        : false;
    },
    showFinEffDate() {
      return this.finEffectShow == 1 &&
        (this.form.id_type == 1 || ![1, 2, 3].includes(this.form.id_poste))
        ? true
        : false;
    },
    finPrevTxt() {
      // 1 = CDD, 2 = CDI
      return this.form.id_type == 1
        ? "Fin prévue"
        : "Fin dernier plan de formation";
    },
    finQuestTxt() {
      // 1 = CDD, 2 = CDI
      return this.form.id_type == 1 ? "Fin différente" : "Fin de contrat";
    },
  },
  methods: {
    changePoste() {
      // sur changement de poste (CDC, CDC-A, MA, MC)
      // si CDI
      if (this.form.id_type == 2) {
        // si pas pas poste de MA ou CDC, CDC-A -> efface fin_prevue_date
        if (![1, 2, 3].includes(this.form.id_poste)) {
          this.form.fin_prevue_date = null;
        } else {
          this.form.fin_prevue_date = this.form.fin_effect_date;
        }
      }
    },
    clearActXurgPrec() {
      if (this.form.activite_xurg == 0) {
        this.form.activite_xurg_prec = null;
      }
    },
    clearFinMotifPrec() {
      if (this.form.id_fin_motif != 4) {
        this.form.fin_motif_prec = null;
      }
    },
    changeType() {
      // sur changement CDD - CDI
      if (this.form.id_type == 2 && ![1, 2, 3].includes(this.form.id_poste)) {
        // si CDI et pas poste de MA ou CDC, CDC-A
        this.form.fin_prevue_date = null;
      }
    },
    changeFinEffectShow() {
      // sur changement de switch finEffectShow
      if (this.finEffectShow) {
        if (this.form.id_type == 2) {
          // SI CDI
          if ([1, 2, 3].includes(this.form.id_poste)) {
            // si poste de MA ou CDC, CDC-A
            this.form.fin_effect_date = this.form.fin_prevue_date;
          }
        }
      } else {
        this.form.fin_effect_date = null;
        this.form.id_fin_motif = null;
        this.form.fin_motif_prec = null;
      }
    },
    changeFinPrevue() {
      // Si CDI ET poste de MA ou CDC, CDC-A ET fin de contrat
      if (
        this.form.id_type == 2 &&
        [1, 2, 3].includes(this.form.id_poste) &&
        this.finEffectShow
      ) {
        this.form.fin_effect_date = this.form.fin_prevue_date;
      }
    },
  },
  watch: {
    contratLoadStatus() {
      // quand contrat chargé (2), maj finEffectShow car pas maj avec les data du form quand change de contrat (id)
      if (this.contratLoadStatus == 2) {
        this.finEffectShow = this.form.fin_effect_date ? true : false;
      }
    },
  },
};
</script>
